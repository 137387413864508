import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIError, APIStatus } from '../../../@types/APIStatus';
import { AcsMessageDetailState } from '../../../@types/reduxStates';
import { axiosInstance } from '../../../utils/axiosConfig';
import { AcsMessageDetailResponse } from '../../../@types/tableRows';

const initialState: AcsMessageDetailState = {
  response: {
    messageId: 0,
    messageName: '',
    f1: 0,
    f2: 0,
    f3: 0
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'acsMessageDetail',
  initialState,
  reducers: {
    getMessageDetail: (state: AcsMessageDetailState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getMessageDetailSuccess: (
      state: AcsMessageDetailState,
      action: PayloadAction<AcsMessageDetailResponse>
    ) => {
      state.response = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getMessageDetailError: (
      state: AcsMessageDetailState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchAcsMessageDetail(messageId: string) {
  return async (dispatch: any) => {
    dispatch(getMessageDetail());
    try {
      const response = await axiosInstance.get(
        `/api/v2/acs/acsMessageDetail/${messageId}`
      );
      dispatch(getMessageDetailSuccess(response.data));
    } catch (error) {
      dispatch(getMessageDetailError(error as APIError));
    }
  };
}

export const {
  getMessageDetail,
  getMessageDetailSuccess,
  getMessageDetailError
} = slice.actions;

export default slice.reducer;
