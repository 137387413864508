import {
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography
} from '@material-ui/core';
import { ReactNode, useRef } from 'react';

import { TableStyleClasses } from '../../../theme/table/TableClasses';
import { useWindowSize } from '../../../hooks/useWindowSize';
import TableHorizontalScroll from '../../general/TableHorizontalScroll';
import { SortBy } from '../../../@types/filters';
import { AcsMessageRowsRow } from '../../../@types/tableRows';
import SummaryHeaderCell from '../../tables/SummaryHeaderCell';

import { unknownToNumber } from '../../../utils/helper';
import useLocales from '../../../hooks/useLocales';
import useAuth from '../../../hooks/useAuth';
import useAcsMessageRows from '../../../hooks/acs/acsMessageDetailTabs/useAcsMessageRows';

const HEIGHT_CELL = 60;
const WIDTH_PRODUCT_CELL = 400;

type Props = {
  rowsPerPage: number;
  sortBy: SortBy;
  onSortChange: (sortBy: SortBy) => void;
};

function AcsMessagesRowsTable({ rowsPerPage, sortBy, onSortChange }: Props) {
  const { currentLang } = useLocales();
  const { height } = useWindowSize();
  const classes = TableStyleClasses({
    height: HEIGHT_CELL,
    numRows: rowsPerPage,
    maxSize: height
  });
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';

  const {
    response: { rows },
    summary
  } = useAcsMessageRows();

  const targetOberverRef = useRef<HTMLTableRowElement | null>(null);
  const euroFormat = new Intl.NumberFormat(currentLang.value, {
    style: 'currency',
    currency: iso,
    maximumFractionDigits: 2
  });
  const percentFormat = new Intl.NumberFormat(currentLang.value, {
    style: 'percent',
    maximumFractionDigits: 1
  });
  const qtyFormat = new Intl.NumberFormat(currentLang.value, {
    maximumFractionDigits: 2
  });

  function getCell(field: string, row: AcsMessageRowsRow): ReactNode {
    switch (field) {
      case 'priceNet': {
        return (
          <Typography variant="body2">
            {`${euroFormat.format(
              unknownToNumber(row[field as keyof AcsMessageRowsRow])
            )}`}
          </Typography>
        );
      }
      case 'f1': {
        return (
          <Typography variant="body2">
            {`${percentFormat.format(
              unknownToNumber(row[field as keyof AcsMessageRowsRow])
            )}`}
          </Typography>
        );
      }
      case 'pos':
      case 'qty': {
        return (
          <Typography variant="body2">
            {`${qtyFormat.format(
              unknownToNumber(row[field as keyof AcsMessageRowsRow])
            )}`}
          </Typography>
        );
      }
      default: {
        return (
          <Typography variant="body2">
            {row[field as keyof AcsMessageRowsRow] as string}
          </Typography>
        );
      }
    }
  }

  return (
    <Paper variant="outlined" className={classes.paper}>
      <TableHorizontalScroll
        forwardedRef={targetOberverRef}
        leftStart={220}
        sortBy={sortBy}
        onSortChange={onSortChange}
        summary={summary}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow ref={targetOberverRef}>
              {summary.map((header, i) => (
                <SummaryHeaderCell
                  key={header.field}
                  index={i}
                  field={header.field}
                  onSortChange={onSortChange}
                  sticky={i === 0}
                  canSort={header.canSort}
                  active={sortBy.by === header.field}
                  header={{
                    align: header.align,
                    label: header.label,
                    subLabel: header.subLabel
                  }}
                  direction={sortBy.order}
                  summary={header.summary}
                  minWidth={header.minWidth}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, key) => (
              <TableRow
                hover
                key={`${key}-${row.pos}`}
                className={classes.tableRow}
              >
                {summary.map((c, i) => (
                  <TableCell
                    className={
                      i === 0
                        ? `${classes.cellSticky} overChart withPaddingStart`
                        : ''
                    }
                    key={`${c.field}-${i}`}
                    align="center"
                    sx={{ padding: '0px' }}
                  >
                    {getCell(c.field, row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableHorizontalScroll>
    </Paper>
  );
}

export default AcsMessagesRowsTable;
