import {
  Box,
  makeStyles,
  TableCell,
  TableSortLabel,
  Theme,
  useTheme,
  Typography,
  Grid
} from '@material-ui/core';
import React from 'react';

import { SortBy } from '../../@types/filters';
import SummaryFirstCell from './cells/SummaryFirstCell';
import { SummaryCell } from '../general/TableRowSummary';
import SummaryDefaultCell from './cells/SummaryDefaultCell';

const useStyles = makeStyles<
  Theme,
  { minWidth: number; useBorderRight?: boolean }
>((theme) => ({
  normal: {
    borderSpacing: 0,
    borderBottom: '1px solid #f1f3f4',
    borderRight: ({ useBorderRight }) =>
      useBorderRight ? `1px solid ${theme.palette.grey[300]}` : '0px',
    zIndex: 3,
    padding: theme.spacing(0, 0, 0, 0),
    '&.MuiTableCell-root:last-of-type': {
      padding: theme.spacing(0, 0, 0, 0)
    },
    minWidth: ({ minWidth }) => `${minWidth}px`,
    '&.sticky': {
      padding: theme.spacing(0, 0, 0, 0),
      // boxShadow: ` inset -2px 0px  4px -2px ${theme.palette.grey[500]}`,
      boxShadow: `inset -2px 0px 3px -2px ${theme.palette.grey[500]}`,
      borderCollapse: 'separate',
      position: 'sticky',
      borderSpacing: 0,
      top: 0,
      left: 0,
      zIndex: 5,
      [theme.breakpoints.down('md')]: {
        position: 'relative',
        boxShadow: ' -3px 0px 0px -2px #f1f3f4 inset'
      }
    }
  },
  summaryUnder: {
    backgroundColor: theme.palette.grey[100],
    minHeight: '80px',
    '&.sticky': {
      boxShadow: `inset -2px 0px 3px -2px ${theme.palette.grey[500]}`
    },
    '&.small': {
      minHeight: '60px'
    }
  }
}));

type Props = {
  index: number;
  sticky: boolean;
  field: string;
  canSort?: boolean;
  active?: boolean;
  direction?: 'asc' | 'desc';
  header: {
    align: 'left' | 'center' | 'right' | 'inherit' | 'justify' | undefined;
    label: string;
    subLabel?: string;
  };
  summary?: SummaryCell;
  custom?: React.ReactNode;
  minWidth?: number;
  showSummary?: boolean;
  onSortChange: (sortBy: SortBy) => void;
  useBorderRight?: boolean;
  infoStyle?: boolean;
  smallHeight?: boolean;
  showTotal?: boolean;
};

function SummaryHeaderCell({
  index,
  sticky,
  header,
  field,
  active = false,
  canSort = false,
  direction = 'desc',
  onSortChange,
  summary,
  minWidth = 160,
  useBorderRight = false,
  showSummary = true,
  infoStyle = false,
  smallHeight = false,
  showTotal = true
}: Props) {
  const classes = useStyles({ minWidth, useBorderRight });
  const theme: Theme = useTheme();

  return (
    <TableCell
      className={`${classes.normal} ${sticky && 'sticky'}`}
      align={header.align}
    >
      <Grid
        container
        flexDirection="column"
        wrap="nowrap"
        justifyContent="space-between"
        sx={{ minWidth: '160px' }}
      >
        <Grid
          item
          textAlign="center"
          alignContent="center"
          sx={{
            height: '60px',
            borderBottom: '1px solid #F1F3F4',
            padding: theme.spacing(0, 2, 0, 2)
          }}
        >
          {canSort ? (
            <TableSortLabel
              active={active}
              direction={direction}
              onClick={() => {
                onSortChange({
                  order: direction === 'desc' ? 'asc' : 'desc',
                  by: field,
                  triggerCall: true
                });
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '18px',
                  paddingBottom: '3px'
                }}
              >
                {!infoStyle && (
                  <Typography variant="body2" noWrap>
                    {header.label}
                  </Typography>
                )}
                {infoStyle && (
                  <Typography
                    variant="body2"
                    noWrap
                    sx={{ fontStyle: 'italic', fontWeight: 'light' }}
                    color="#899499"
                  >
                    {header.label}
                  </Typography>
                )}
                {header.subLabel && (
                  <Typography variant="caption" noWrap>
                    {header.subLabel}
                  </Typography>
                )}
              </Box>
            </TableSortLabel>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body2" noWrap>
                {header.label}
              </Typography>
              {header.subLabel && (
                <Typography variant="caption" noWrap>
                  {header.subLabel}
                </Typography>
              )}
            </Box>
          )}
        </Grid>
        {showSummary && (
          <Grid
            item
            textAlign="center"
            alignContent="center"
            className={`${classes.summaryUnder} ${smallHeight ? 'small' : ''} ${
              sticky ? 'sticky' : ''
            }`.trim()}
          >
            {index === 0 && !summary && (
              <SummaryFirstCell showTotal={showTotal} />
            )}
            {index > 0 && summary && summary.type === 'no' && (
              <Box sx={{ width: '100%', minWidth: '120px', height: '100%' }} />
            )}
            {index > 0 &&
              summary &&
              summary.type === 'custom' &&
              summary.custom}
            {index > 0 &&
              summary &&
              summary.type !== 'no' &&
              summary.type !== 'custom' && (
                <SummaryDefaultCell cell={summary} />
              )}
          </Grid>
        )}
      </Grid>
    </TableCell>
  );
}

export default SummaryHeaderCell;
