import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchAcsMessageRows } from '../../../redux/slices/acs/acsMessageDetailTabs/acsMessageRows';
import { RootState } from '../../../redux/rootReducer';
import { TableHeaderCell } from '../../../@types/tableRows';
import { divideIfNotZero, unknownToNumber } from '../../../utils/helper';
import useLocales from '../../useLocales';
import {
  DateRangeFilter,
  SortBy,
  TableSkipLimit
} from '../../../@types/filters';

// ----------------------------------------------------------------------

function useAcsMessageRows() {
  const dispatch = useDispatch();
  const { error, APIStatus, response } = useSelector(
    (state: RootState) => state.acsMessageRows
  );
  const { t } = useTranslation(['component', 'filter']);
  const { currentLang } = useLocales();

  const summary: Array<TableHeaderCell> = useMemo(() => {
    const qty = unknownToNumber(
      response.rows.map((row) => row.qty).reduce((a, b) => a + b, 0)
    );
    const priceNet =
      response.rows.length > 0
        ? unknownToNumber(
            divideIfNotZero(
              response.rows
                .map((row) => row.priceNet)
                .reduce((a, b) => a + b, 0),
              response.rows.length
            )
          )
        : 0;

    return [
      {
        id: 1,
        field: 'pos',
        label: t('component:pos'),
        align: 'center',
        canSort: true,
        minWidth: 220
      },
      {
        id: 2,
        field: 'gtin',
        label: t('component:gtin'),
        align: 'center',
        canSort: true,
        minWidth: 160
      },
      {
        id: 3,
        field: 'sku',
        label: t('component:sku'),
        align: 'center',
        canSort: true,
        minWidth: 160
      },
      {
        id: 4,
        field: 'qty',
        label: t('component:qty'),
        align: 'center',
        canSort: true,
        minWidth: 160,
        summary: {
          value: qty,
          value2: response.total.qty,
          type: 'qty',
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 5,
        field: 'priceNet',
        label: t('component:price_net'),
        align: 'center',
        canSort: true,
        minWidth: 160,
        summary: {
          value: priceNet,
          value2: response.total.priceNet,
          type: 'currency',
          align: 'center',
          paddingRight: '8px',
          calculation: 'average'
        }
      },
      {
        id: 6,
        field: 'shipGln',
        label: t('component:ship_gln'),
        align: 'center',
        canSort: true,
        minWidth: 160
      }
    ];
  }, [response, currentLang]);

  const abortController = useRef<AbortController>(new AbortController());

  const cancelFetch = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  const getAcsMessageRows = (
    messageId: string,
    timePeriod: DateRangeFilter,
    sortBy: SortBy,
    pagination: TableSkipLimit
  ) => {
    dispatch(
      fetchAcsMessageRows(
        abortController.current,
        messageId,
        timePeriod,
        sortBy,
        pagination
      )
    );
  };

  return {
    error,
    APIStatus,
    response,
    summary,
    getAcsMessageRows,
    cancelFetch
  };
}

export default useAcsMessageRows;
