import {
  Divider,
  Typography,
  Box,
  useTheme,
  Theme,
  Grid
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

type Props = {
  showTotal?: boolean;
};

function SummaryFirstCell({ showTotal = true }: Props) {
  const { t: translate } = useTranslation(['component']);
  const theme: Theme = useTheme();

  if (!showTotal) {
    return (
      <Grid container spacing={2} padding={2} alignItems="center">
        <Grid item>
          <Typography
            variant="body1"
            textAlign="start"
            fontWeight={700}
            color="text.primary"
          >
            {translate('summary')}
          </Typography>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Divider sx={{ width: '100%' }} />
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            textAlign="start"
            fontWeight={700}
            color="text.primary"
          >
            {translate('current_page')}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minWidth: '180px',
        paddingLeft: '16px',
        paddingRight: '16px'
      }}
    >
      <Typography
        variant="body1"
        textAlign="start"
        fontWeight={700}
        color={theme.palette.text.primary}
        sx={{ paddingRight: '4px', height: '26px' }}
      >
        {translate('summary')}
      </Typography>

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-end',
          width: '80px'
        }}
      >
        <Typography
          variant="body1"
          textAlign="start"
          fontWeight={700}
          color={theme.palette.text.primary}
        >
          {translate('current_page')}
        </Typography>
        <Divider sx={{ width: '100%' }} />
        <Typography
          variant="body1"
          textAlign="start"
          fontWeight={700}
          color="text.secondary"
        >
          {translate('total')}
        </Typography>
      </Box>
    </Box>
  );
}

export default SummaryFirstCell;
