import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  Checkbox,
  makeStyles,
  TextField,
  Theme
} from '@material-ui/core';
import React, { useMemo } from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles<Theme>((theme) => ({
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(255, 0, 0, 0.2)'
    }
  }
}));

type OptionType = {
  [key: string]: any;
};

type Props<T> = {
  label: string;
  placeholder: string;
  disabled?: boolean;
  valueSelected: T[] | undefined;
  options: readonly T[];
  isAdminField?: boolean;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: T[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T> | undefined
  ) => void;
  onClose: (
    event: React.SyntheticEvent,
    reason: AutocompleteCloseReason
  ) => void;
  onOpen: (event: React.SyntheticEvent) => void;
  getOptionLabel: (option: T) => string;
  getOptionSelected: (option: T, value: T) => boolean;
};

function AutocompleteMulti<T extends OptionType>({
  label,
  placeholder,
  valueSelected,
  options,
  onChange,
  onClose,
  onOpen,
  getOptionLabel,
  getOptionSelected,
  isAdminField = false,
  disabled = false
}: Props<T>) {
  const classes = useStyles();

  const optionsIndexed = useMemo(
    () => options.map((opt, i) => ({ ...opt, index: i })),
    [options]
  );

  // className={`${classes.content} ${isAdminField ? classes.admin : '' }`}
  return (
    <Autocomplete
      onClose={onClose}
      onOpen={onOpen}
      disabled={disabled}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.index}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {getOptionLabel(option)}
        </li>
      )}
      size="small"
      multiple
      getOptionLabel={getOptionLabel}
      value={valueSelected}
      getOptionSelected={getOptionSelected}
      id={`autocomplete-multi-${label}`}
      options={optionsIndexed}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          className={isAdminField ? classes.textField : undefined}
        />
      )}
    />
  );
}

export default AutocompleteMulti;
