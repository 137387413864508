import {
  alpha,
  Box,
  makeStyles,
  Theme,
  Typography,
  Fade,
  useTheme,
  Divider,
  Stack,
  Link
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import { BundleComponent } from '../../../@types/tableRows';
import { getMinValueOrNull } from '../../../utils/helper';

import { LightTooltip } from '../../general/LightTooltip';

const useStyles = makeStyles<Theme, { color: string; noData: boolean }>(
  (theme) => ({
    root: {
      height: ({ noData }) => (noData ? 'auto' : '100%'),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: ({ noData }) => (noData ? 'flex-start' : 'center'),
      alignItems: 'center',
      '&.bundle': {
        paddingLeft: '28px'
      }
    },
    status: {
      borderRadius: 7,
      padding: '4px 10px',
      marginTop: '4px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: ({ color }) => color,
      backgroundColor: ({ color }) => alpha(color, 0.1)
    }
  })
);

type StockStatus = {
  label: string;
  color: string;
};

function getStockStatus(
  lowStockConfig: number,
  currentStock?: number | null
): StockStatus {
  if (
    currentStock === null ||
    currentStock === undefined ||
    isNaN(currentStock)
  ) {
    return {
      label: 'component:stock_nodata',
      color: '#FF1943'
    };
  }
  if (currentStock <= 0) {
    return {
      label: 'component:stock_empty',
      color: '#FF1943'
    };
  }
  if (currentStock > 0 && currentStock <= lowStockConfig) {
    return {
      label: 'component:stock_low',
      color: '#FFA319'
    };
  }
  if (currentStock > lowStockConfig) {
    return {
      label: 'component:stock_available',
      color: '#57CA22'
    };
  }
  return {
    label: 'component:stock_nodata',
    color: '#FF1943'
  };
}

type StockCellProps = {
  stockLow: number;
  stock?: number;
  bundleItems?: Array<BundleComponent>;
};

export function CurrentStockCell({
  stock,
  stockLow = 4,
  bundleItems = []
}: StockCellProps) {
  const { t } = useTranslation(['component']);
  const theme: Theme = useTheme();
  const stockStatus: StockStatus = getStockStatus(stockLow, stock);
  const classes = useStyles({
    color: stockStatus.color,
    noData: !stock
  });

  const stockBundle =
    bundleItems.map((b) => b.psyStock ?? 0).reduce((a, b) => a + b, 0) ?? 0;
  const minStock = getMinValueOrNull(bundleItems.map((b) => b.psyStock));
  const bundleStatus: StockStatus = getStockStatus(stockLow, minStock);

  if (bundleItems.length > 0) {
    return (
      <Box display="flex" alignItems="bottom" justifyContent="center">
        <Box className={`${classes.root} bundle`}>
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            {`${stockBundle} (${t('component:bundle')})`}
          </Typography>
          <Box className={classes.status}>
            <Typography variant="caption" sx={{ fontWeight: 300 }}>
              {t(bundleStatus.label)}
            </Typography>
          </Box>
        </Box>
        <LightTooltip
          placement="top"
          title={
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
            >
              {bundleItems.map((item, i) => {
                const stockData: StockStatus = getStockStatus(
                  stockLow,
                  item.psyStock
                );
                return (
                  <Box
                    key={`${i}_${item.variationId}`}
                    className={classes.root}
                    sx={{ paddingTop: '8px', paddingBottom: '8px' }}
                  >
                    <Link
                      sx={{
                        color: 'text.primary',
                        textDecoration: 'none',
                        fontWeight: 600,
                        '&:hover': {
                          color: 'primary.main'
                        }
                      }}
                      variant="body2"
                      component={RouterLink}
                      to={`/product/${item.articleId}/${0}`}
                    >
                      {`${item.variationId} - ${item.articleName}`}
                    </Link>
                    <Stack
                      direction="row"
                      spacing={1}
                      divider={<Divider orientation="vertical" flexItem />}
                      sx={{ marginTop: '8px' }}
                    >
                      {item.psyStock && (
                        <Typography
                          variant="body2"
                          color="text.primary"
                          sx={{ fontWeight: 700 }}
                        >
                          {item.psyStock}
                        </Typography>
                      )}
                      <Box
                        sx={{
                          color: stockData.color,
                          backgroundColor: alpha(stockData.color, 0.1),
                          borderRadius: '7px',
                          padding: '4px 10px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <Typography variant="caption" sx={{ fontWeight: 300 }}>
                          {t(stockData.label)}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                );
              })}
            </Box>
          }
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 300 }}
        >
          <Box
            sx={{
              paddingTop: '2px',
              paddingLeft: '8px',
              '&:hover': { cursor: 'help' }
            }}
          >
            <Icon
              width={20}
              height={20}
              icon="bi:eye-fill"
              color={theme.palette.grey[400]}
            />
          </Box>
        </LightTooltip>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      {stock && (
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          {stock}
        </Typography>
      )}

      <Box className={classes.status}>
        <Typography variant="caption" sx={{ fontWeight: 300 }}>
          {t(stockStatus.label)}
        </Typography>
      </Box>
    </Box>
  );
}
