import { Icon } from '@iconify/react';
import {
  Box,
  CardContent,
  IconButton,
  TablePagination,
  Theme,
  useMediaQuery,
  useTheme,
  Card,
  CardHeader,
  Grid
} from '@material-ui/core';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import _debounce from 'lodash/debounce';
import { format, subDays } from 'date-fns';
import DialogDownloadCSV from '../../general/dialog/DialogDownloadCSV';
import { TablePaginationClasses } from '../../../theme/table/TableClasses';
import {
  DateRangeFilter,
  SortBy,
  TableSkipLimit
} from '../../../@types/filters';
import { CSVExportData } from '../../general/ExportCSV';
import AcsMessagesRowsTable from './AcsMessagesRowsTable';
import useAcsMessageRows from '../../../hooks/acs/acsMessageDetailTabs/useAcsMessageRows';
import useAuth from '../../../hooks/useAuth';
import SelectTimePeriod from '../../filters/SelectTimePeriod';
import { APIStatus } from '../../../@types/APIStatus';

type Props = {
  title?: string;
};

function AcsMessageRows({ title = 'acs_message_rows' }: Props) {
  const { t } = useTranslation(['component', 'filter', 'menu', 'global']);
  const { user } = useAuth();
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = TablePaginationClasses();

  const {
    APIStatus: state,
    response: { totalCount: count },
    getAcsMessageRows,
    cancelFetch
  } = useAcsMessageRows();

  const rowsPerPageOpts = [10, 25, 50, 100];
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOpts[0]);
  const [sortBy, setSortBy] = useState<SortBy>({
    by: 'pos',
    order: 'desc'
  });
  const [controller, setController] = useState<AbortController | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<CSVExportData>({
    fileName: '',
    data: [],
    headers: [],
    date: { startFilter: '', endFilter: '' }
  });
  const csvLinkEl = useRef<any>(null);
  const [timePeriod, setTimePeriod] = useState<DateRangeFilter>({
    startFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
    endFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd')
  });

  const debounceFilter = useMemo(
    () =>
      _debounce(
        (tPeriod: DateRangeFilter, sBy: SortBy, skLimit: TableSkipLimit) =>
          getAcsMessageRows('123', tPeriod, sBy, skLimit),
        300
      ),
    []
  );

  useEffect(() => {
    debounceFilter(timePeriod, sortBy, {
      skip: page * rowsPerPage,
      limit: rowsPerPage
    });
    return () => cancelFetch();
  }, [timePeriod, sortBy, page, rowsPerPage]);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    p: number
  ) => {
    if (p !== page) {
      setPage(p);
    }
  };

  const handleDownload = async (range: { from: number; to: number }) => {
    setOpen(false);
    setLoading(false);
    /* if (!isLoading) {
          const newController = new AbortController();
          setController(newController);
          const { signal } = newController;
          setLoading(true);
          try {
            const data = await getAcsMessages(
                cleanHash,
                sortBy,
                {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage
                }
            );
            const dataCSV: CSVExportData = processCsvExportData(
              data.rows,
              data.rowsPrev
            );
            setOpen(false);
            setLoading(false);
            setCsvData(dataCSV);
          } catch (error) {
            setLoading(false);
          }
        } */
  };

  const handleCancelFetchCSV = () => {
    if (isLoading && controller) {
      controller.abort();
      setController(null);
      setOpen(false);
    }
    setOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={t(`menu:${title}`)}
            action={
              <IconButton
                aria-label="download csv"
                onClick={() => handleOpenDialog()}
                color="primary"
              >
                <Icon width={30} icon="ph:file-csv-duotone" color="primary" />
              </IconButton>
            }
          />
          <CardContent>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={12} md={6} lg={4}>
                <SelectTimePeriod
                  disabled={state === APIStatus.PENDING}
                  startFilter={timePeriod.startFilter}
                  endFilter={timePeriod.endFilter}
                  onChangePeriod={setTimePeriod}
                />
              </Grid>

              <Grid item xs={12}>
                <AcsMessagesRowsTable
                  rowsPerPage={rowsPerPage}
                  sortBy={sortBy}
                  onSortChange={setSortBy}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <TablePagination
                component="div"
                className={classes.root}
                rowsPerPageOptions={rowsPerPageOpts}
                labelRowsPerPage={!isMobile ? t('global:rows_per_page') : ''}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
                showFirstButton={true}
                showLastButton={true}
              />
            </Box>
            <DialogDownloadCSV
              page={page}
              rowsPerPage={rowsPerPage}
              maxRows={count}
              onDownload={handleDownload}
              isDownloading={isLoading}
              id="csv-options"
              keepMounted
              open={open}
              onClose={handleCancelFetchCSV}
            />
            <CSVLink
              separator=";"
              asyncOnClick={true}
              data={csvData.data}
              headers={csvData.headers}
              filename={csvData.fileName}
              ref={csvLinkEl}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default AcsMessageRows;
