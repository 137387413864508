import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { fetchAcsMessageDetail } from '../../redux/slices/acs/acsMessageDetail';

// ----------------------------------------------------------------------

function useAcsMessageDetail() {
  const dispatch = useDispatch();
  const { error, APIStatus, response } = useSelector(
    (state: RootState) => state.acsMessageDetail
  );

  return {
    error,
    APIStatus,
    response,
    getAcsMessageDetail: (messageId: string) =>
      dispatch(fetchAcsMessageDetail(messageId))
  };
}

export default useAcsMessageDetail;
