//
import { useHistory, useParams } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Tabs,
  Tab,
  Button,
  useMediaQuery,
  Theme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// components
import Grid from '@material-ui/core/Grid';
import { TabContext, TabPanel } from '@material-ui/lab';
import useConfig from '../hooks/useConfig';
import Page from '../components/Page';
import { APIStatus } from '../@types/APIStatus';
import { useOrderStyles } from '../components/order/styles';
import { textStyles } from '../theme/TextClasses';
import SidebarIcon from '../components/general/SidebarIcon';
import { HeaderBalance } from '../components/order/HeaderBalance';
import HeaderWidget from '../components/order/HeaderWidget';
import ic_edi_acs from '../lotties/ic_edi_acs.json';
import useAcsMessageDetail from '../hooks/acs/useAcsMessageDetail';
import AcsMessageRows from '../components/acs/acsMessageDetailTabs/AcsMessageRows';

export default function ACSMessage() {
  const { id } = useParams<{ id: string }>();
  const classes = useOrderStyles();
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const history = useHistory();
  const textClasses = textStyles({});
  const tabsRef = useRef<HTMLDivElement>(null);
  const [tabSelected, setTabSelected] = useState<string>('0');

  const {
    APIStatus: state,
    getAcsMessageDetail,
    response: { messageId, messageName, f1, f2, f3 }
  } = useAcsMessageDetail();

  const { config } = useConfig();
  const { t: translate } = useTranslation(['menu', 'component']);

  useEffect(() => {
    getAcsMessageDetail(id);
  }, [id]);

  if (state === APIStatus.REJECTED) {
    return <div>{`No message found with ID: ${id}`}</div>;
  }

  return (
    <Page title={`${config.company} - ${translate('order')}`}>
      <Container maxWidth="xl" sx={{ marginTop: '8px' }}>
        <Grid container spacing={4}>
          <Grid container item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Button variant="text" onClick={() => history.goBack()}>
                    {translate('global:backLabel')}
                  </Button>
                </Grid>
                <Paper variant="outlined" className={classes.container}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={5} md={3}>
                      <SidebarIcon
                        json={ic_edi_acs}
                        stop={true}
                        width={isMobile ? 105 : 210}
                        height={isMobile ? 105 : 210}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={7}
                      md={6}
                      className={classes.description}
                    >
                      <Grid container item xs={12}>
                        <Grid item xs={6} md={4}>
                          <Typography
                            variant={isMobile ? 'body1' : 'h6'}
                            fontWeight={500}
                            color="text.primary"
                          >
                            {translate('component:message_id')}
                          </Typography>
                        </Grid>

                        <Grid item xs={6} md={8}>
                          <Typography
                            variant={isMobile ? 'body1' : 'h6'}
                            fontWeight={700}
                            color="text.primary"
                          >
                            {messageId}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container item xs={12}>
                        <Grid item xs={6} md={4}>
                          <Typography
                            variant={isMobile ? 'body1' : 'h6'}
                            fontWeight={500}
                            color="text.primary"
                          >
                            {translate('component:message_f1')}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <Typography
                            variant={isMobile ? 'body1' : 'h6'}
                            fontWeight={700}
                            color="text.primary"
                          >
                            {f1 || '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={6} md={4}>
                          <Typography
                            variant={isMobile ? 'body1' : 'h6'}
                            fontWeight={500}
                            color="text.primary"
                          >
                            {translate('component:message_f2')}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <Typography
                            variant={isMobile ? 'body1' : 'h6'}
                            fontWeight={700}
                            color="text.primary"
                          >
                            {f2 || '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent="flex-end"
                      xs={12}
                      md={3}
                    >
                      <Grid item xs={12} sm={6}>
                        <HeaderBalance
                          isMobile={isMobile}
                          title={translate('component:article_qty')}
                          value={messageName}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <HeaderBalance
                          isMobile={isMobile}
                          type="currency"
                          title={translate('component:net_revenue')}
                          value={0}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <HeaderBalance
                          isMobile={isMobile}
                          type="currency"
                          title={translate('component:revenue_brutto')}
                          value={0}
                        />
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.widgetRoot}>
                      <Grid
                        container
                        item
                        xs={12}
                        spacing={2}
                        className={classes.widgetContainer}
                      >
                        <Grid item xs={12} sm={6} lg={3}>
                          <HeaderWidget title={translate('component:channel')}>
                            <Box className={`${classes.centerBox} column`}>
                              <Grid container spacing={2}>
                                <Grid item justifyContent="center" xs={12}>
                                  <Typography
                                    variant="body1"
                                    fontWeight={700}
                                    color="text.primary"
                                    textAlign="center"
                                  >
                                    {messageName}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </HeaderWidget>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                          <HeaderWidget
                            title={translate('component:actual_status')}
                          >
                            <Box className={`${classes.centerBox} column`}>
                              <Typography
                                variant="body1"
                                fontWeight={700}
                                color="text.primary"
                                align="center"
                              >
                                {messageName}
                              </Typography>
                            </Box>
                          </HeaderWidget>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                          <HeaderWidget
                            title={translate('component:customer_class')}
                          >
                            <Box className={`${classes.centerBox} column`}>
                              <Typography
                                variant="body1"
                                fontWeight={700}
                                color="text.primary"
                              >
                                {messageName}
                              </Typography>
                            </Box>
                          </HeaderWidget>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                          <HeaderWidget
                            title={translate('component:invoicing')}
                          >
                            <Box className={`${classes.centerBox} column`}>
                              <Typography
                                variant="body1"
                                fontWeight={700}
                                color="text.primary"
                              >
                                {translate('component:no_invoice')}
                              </Typography>

                              <Grid container spacing={2}>
                                <Grid container item spacing={2} xs={6}>
                                  <Grid item justifyContent="center" xs={12}>
                                    <Typography
                                      variant="body1"
                                      fontWeight={700}
                                      color="text.primary"
                                      textAlign="center"
                                    >
                                      {translate('component:created')}
                                    </Typography>
                                  </Grid>
                                  <Grid item justifyContent="center" xs={12}>
                                    <Typography
                                      variant="body1"
                                      fontWeight={700}
                                      color="text.primary"
                                      textAlign="center"
                                    >
                                      {messageName}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid container item spacing={2} xs={6}>
                                  <Grid item justifyContent="center" xs={12}>
                                    <Typography
                                      variant="body1"
                                      fontWeight={700}
                                      color="text.primary"
                                      textAlign="center"
                                    >
                                      {translate('filter:payment_type')}
                                    </Typography>
                                  </Grid>
                                  <Grid item justifyContent="center" xs={12}>
                                    <Typography
                                      variant="body1"
                                      fontWeight={700}
                                      color="text.primary"
                                      textAlign="center"
                                    >
                                      {messageName}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </HeaderWidget>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <TabContext value={tabSelected}>
              <Box
                className={classes.boxContainer}
                component="div"
                ref={tabsRef}
              >
                <Tabs
                  className={classes.tabs}
                  variant="scrollable"
                  scrollButtons="auto"
                  value={tabSelected}
                  onChange={(e, v) => setTabSelected(v)}
                  aria-label="Message detail tabs"
                >
                  <Tab
                    className={classes.tab}
                    label={translate('messages')}
                    value="0"
                  />
                  <Tab
                    className={classes.tab}
                    label={translate('timeline')}
                    value="1"
                  />
                </Tabs>
              </Box>
              <Paper className={classes.tabContainer} variant="outlined">
                <Typography
                  variant="h5"
                  className={`${textClasses.ellipsis} ${classes.tabTitle}`}
                >
                  <AcsMessageRows />
                </Typography>

                <TabPanel value="0">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* <OrderArticles /> */}
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value="1">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* <OrderTimeline /> */}
                    </Grid>
                  </Grid>
                </TabPanel>
              </Paper>
            </TabContext>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
