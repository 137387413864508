import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../utils/axiosConfig';
import { AcsMessagesState, AcsMessagesResponse } from '../../../@types/acs';
import { APIStatus, APIError } from '../../../@types/APIStatus';
import { SortBy, TableSkipLimit } from '../../../@types/filters';

const initialState: AcsMessagesState = {
  params: '',
  APIStatus: APIStatus.IDLE,
  response: {
    rows: [],
    total: {
      name: '',
      f1: 0,
      f2: 0,
      f3: 0,
      f4: 0
    },
    count: 0
  }
};

const slice = createSlice({
  name: 'acsMessages',
  initialState,
  reducers: {
    setUrlParams(state: AcsMessagesState, action: PayloadAction<string>) {
      state.params = action.payload;
    },
    getAcsMessages: (state: AcsMessagesState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getAcsMessagesSuccess: (
      state: AcsMessagesState,
      action: PayloadAction<AcsMessagesResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getAcsMessagesError: (
      state: AcsMessagesState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function updateParams(urlParams: string) {
  return async (dispatch: any) => {
    try {
      dispatch(setUrlParams(urlParams));
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchAcsMessages(
  abortController: AbortController,
  params: Record<string, any>,
  { by, order }: SortBy,
  { skip, limit }: TableSkipLimit
) {
  return async (dispatch: any) => {
    try {
      dispatch(getAcsMessages());
      const response = await axiosInstance.get('/api/v2/acs/tab1/messageRows', {
        signal: abortController.signal,
        params: { ...params, by, order, skip, limit }
      });
      dispatch(getAcsMessagesSuccess(response.data));
    } catch (error) {
      dispatch(getAcsMessagesError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setUrlParams,
  getAcsMessages,
  getAcsMessagesSuccess,
  getAcsMessagesError
} = slice.actions;
