import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { AcsMessageRowsState } from '../../../../@types/reduxStates';
import { APIError, APIStatus } from '../../../../@types/APIStatus';
import { AcsMessageRowsResponse } from '../../../../@types/responsesAPI';
import { axiosInstance } from '../../../../utils/axiosConfig';
import {
  DateRangeFilter,
  SortBy,
  TableSkipLimit
} from '../../../../@types/filters';

const initialState: AcsMessageRowsState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    }
  },
  response: {
    rows: [],
    total: {
      pos: 0,
      gtin: '',
      sku: '',
      qty: 0,
      priceNet: 0,
      shipGln: ''
    },
    totalCount: 0
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'acsMessageRows',
  initialState,
  reducers: {
    setFilters: (
      state: AcsMessageRowsState,
      action: PayloadAction<DateRangeFilter>
    ) => {
      state.filters.timePeriod = action.payload;
    },
    getMessageRows: (state: AcsMessageRowsState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getMessageRowsSuccess: (
      state: AcsMessageRowsState,
      action: PayloadAction<AcsMessageRowsResponse>
    ) => {
      state.response = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getMessageRowsError: (
      state: AcsMessageRowsState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchAcsMessageRows(
  abortController: AbortController,
  messageId: string,
  { startFilter, endFilter }: DateRangeFilter,
  { by, order }: SortBy,
  { skip, limit }: TableSkipLimit
) {
  return async (dispatch: any) => {
    try {
      dispatch(setFilters({ startFilter, endFilter }));
      dispatch(getMessageRows());
      const response = await axiosInstance.get('/api/v2/acs/tab1/messageRows', {
        signal: abortController.signal,
        params: {
          messageId,
          ...{ from: startFilter, till: endFilter },
          by,
          order,
          skip,
          limit
        }
      });
      dispatch(getMessageRowsSuccess(response.data));
    } catch (error) {
      dispatch(getMessageRowsError(error as APIError));
    }
  };
}

export const {
  setFilters,
  getMessageRows,
  getMessageRowsSuccess,
  getMessageRowsError
} = slice.actions;

export default slice.reducer;
